function Footer() {
    return (
        <footer className="py-12 border-t">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                    <p className="text-sm text-muted-foreground">
                        © {new Date().getFullYear()} Portfolio. All rights reserved.
                    </p>
                    <div className="flex gap-6">
                        <a
                            href="https://github.com/heregoes"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm text-muted-foreground hover:text-primary transition-colors"
                        >
                            GitHub
                        </a>
                        <a
                            href="https://linkedin.com/in/gabrielnyante"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-sm text-muted-foreground hover:text-primary transition-colors"
                        >
                            LinkedIn
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;