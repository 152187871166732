import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

function Navigation() {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50)
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  const handleClick = (href) => {
    setIsOpen(false)
    const element = document.querySelector(href)
    element?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const navItems = [
    {
      label: "About",
      href: "#about"
    },
    {
      label: "Work",
      href: "#work"
    },
    {
      label: "Blog",
      href: "#blog"
    },
    {
      label: "Contact",
      href: "#contact"
    }
  ]

  return (
      <nav id="nav" className={cn(
          "fixed top-0 w-full z-50 transition-all duration-300 bg-transparent", (isScrolled && "bg-white") || (isOpen && "bg-white")
      )}>
        <div className={cn("container mx-auto px-4 text-white", isScrolled && 'text-black')}>
          <div className="flex h-20 items-center justify-between">
            <Link
                to="#main"
                className="text-xl font-bold relative group"
                onClick={() => handleClick('#main')}
            >
              <span className={`relative z-10 ${isOpen && 'text-black'}`}>Gabriel Nyante</span>
              <span className="absolute inset-x-0 bottom-0 h-0.5 bg-primary origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
            </Link>

            <div className="flex items-center gap-4">
              {/* Desktop Navigation */}
              <div className="hidden md:flex space-x-8">
                {navItems.map((item) => (
                    <Link
                        key={item.label}
                        to={item.href}
                        className="text-base font-medium relative group"
                        onClick={() => handleClick(item.href)}
                    >
                  <span className="relative z-10 transition-colors duration-300 group-hover:text-primary">
                    {item.label}
                  </span>
                      <span className="absolute inset-x-0 bottom-0 h-0.5 bg-primary origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out" />
                      <span className="absolute -inset-x-2 -inset-y-1 bg-primary/5 rounded-lg scale-95 opacity-0 group-hover:opacity-100 group-hover:scale-100 transition-all duration-300" />
                    </Link>
                ))}
              </div>
              {/*<ModeToggle />*/}

              {/* Mobile Menu Button */}
              <button
                  // variant="ghost"
                  // size="icon"
                  className="md:hidden "
                  onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                    <div className='text-4xl text-black'>
                      <ion-icon name="close-outline"></ion-icon>
                    </div>
                ) : (
                    <div className='text-4xl'>
                      <ion-icon name="menu-outline"></ion-icon>
                    </div>

                )}
                <span className="sr-only">Toggle menu</span>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        <div
            className={cn(
                "fixed inset-0 top-20 transition-transform duration-300 ease-in-out bg-white",
                isOpen ? "translate-x-0" : "translate-x-full"
            )}
        >
          <div className="container px-4 py-8 bg-background">
            <div className="flex flex-col space-y-8">
              {navItems.map((item) => (
                  <Link
                      key={item.label}
                      to={item.href}
                      className="text-2xl font-semibold relative group"
                      onClick={() => handleClick(item.href)}>
                    <span className="relative z-10 transition-colors duration-300 group-hover:text-primary">
                      {item.label}
                    </span>
                    <span className="absolute inset-x-0 bottom-0 h-0.5 bg-primary origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out" />
                  </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>
  )
}

export default Navigation;