import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy } from 'react';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Layout from "./components/Layout";
import Loader from './components/Loader';
import "./App.css";

const Home = lazy(() => import("./pages/Home"));
const NotFound = lazy(() => import("./pages/NotFound"));

export const routes = [
  { path: "/", element: <Home />, key: "home" },
  { path: "*", element: <NotFound />, key: "not-found" },
];

function App() {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <Layout>
            <Suspense fallback={<Loader />}>
              <Routes>
                {routes.map(({ path, element, key }) => (
                  <Route key={key} path={path} element={element} />
                ))}
              </Routes>
            </Suspense>
          </Layout>
          <Toaster />
        </BrowserRouter>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;
